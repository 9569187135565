'use client'

import { useEffect, useMemo, useRef, useState, type PropsWithChildren } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import useGetOAuthConfig from 'app/api/auth/getOAuthConfig/_helper/useGetOAuthConfig'
import { Provider as ContextProvider, DEFAULT_DATA } from './Context'
import { isPresent } from 'lib/config/helper'

function Provider({ children }: Readonly<PropsWithChildren>) {
  const appleLoadedRef = useRef(false)
  const [appleLoaded, setAppleLoaded] = useState(false)

  const {
    data: oauthConfig,
  } = useGetOAuthConfig()

  //
  // Load apple auth library
  useEffect(() => {
    if (!window || !document) return

    const existingScript = document.getElementById('apple-auth-js')

    if (existingScript && appleLoadedRef?.current) return

    existingScript?.remove()

    const script = document.createElement('script')
    script.id = 'apple-auth-js'
    script.type = 'text/javascript'
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    script.defer = true
    script.addEventListener('load', () => {
      appleLoadedRef.current = true
      setAppleLoaded(true)
    })

    const body = document.getElementsByTagName('body')[0]
    body.appendChild(script)
  }, [])

  //
  // Build context data
  const contextData = useMemo(() => ({
    ...DEFAULT_DATA,
    appleLoaded,
    googleLoaded: isPresent(oauthConfig?.data?.google_oauth_client_id),
    appleOauthClientId: oauthConfig?.data?.apple_oauth_client_id,
    appleOauthState: oauthConfig?.data?.apple_oauth_state,
  }), [
    appleLoaded,
    oauthConfig?.data?.google_oauth_client_id,
    oauthConfig?.data?.apple_oauth_client_id,
    oauthConfig?.data?.apple_oauth_state
  ])

  // Render
  return (
    <ContextProvider value={contextData}>
      <GoogleOAuthProviderWrapper clientId={oauthConfig?.data?.google_oauth_client_id ?? ''}>
        {children}
      </GoogleOAuthProviderWrapper>
    </ContextProvider>
  )
}

export default Provider

function GoogleOAuthProviderWrapper({
  clientId,
  children,
}: Readonly<PropsWithChildren & { clientId: string }>) {
  return isPresent(clientId) ? (
    <GoogleOAuthProvider clientId={clientId}>
      {children}
    </GoogleOAuthProvider>
  ) : (
    <>{children}</>
  )
}
