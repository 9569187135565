'use client'

import { type PropsWithChildren } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import OAuthProvider from 'lib/oauth/Provider'
import AnalyticsProvider from 'lib/analytics/AnalyticsProvider'
import { getQueryClient } from 'lib/utils/getQueryClient'

function ClientProviders({ children }: Readonly<PropsWithChildren>) {
  const queryClient = getQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <OAuthProvider>
          {children}
        </OAuthProvider>
      </AnalyticsProvider>
    </QueryClientProvider>
  )
}

export default ClientProviders
